import React from "react"
import GridLayout from "../Layout/GridLayout"
import { Seo } from "../seo"
import { IAppRoutes, AppRoutes } from "../Layout/Routing"
import { useI18next } from "../i18n"

import Profile from "../User/Profile"

const AppPage = (props) => {
  const { t, navigate } = useI18next()

  //console.log("Context: ", props.pageContext)
  //console.log("Context: ", props["*"])

  // Skip build, Browsers only
  if (typeof window !== "undefined") {
    setTimeout(() => {
      navigate(props["*"])
    }, 1000)
  }

  return (
    <GridLayout>
      <div className="row row--center mt-m pb-s">
        <div className="col--12">Attendere, è in corso il reindirizzamento alla pagina desiderata...</div>
      </div>
    </GridLayout>
  )
}

export default AppPage
